<template>
  <div>
    <!-- <div class="__loading" id="__loading">
      <img src="https://mohasagor.com/public/storage/images/unnamed.gif" alt="" />

    </div> -->
    <div class="header">
      <!-- info and social header -->
      <div class="header-top">
        <div
          class="
            container-fluid
            d-flex
            justify-content-between
            align-items-center
          "
        >
          <div class="header_left d-flex">
            <li>
              <a href="tel:09636-203040" class="">
                <i class="bi bi-telephone-inbound-fill"></i>
                09636-203040(10 AM - 10 PM)
              </a>
            </li>
            <li>
              <a href="mailto:support@mohasagor.com">
                <i class="bi bi-envelope"></i>
                support@mohasagor.com
              </a>
            </li>
          </div>
          <div class="header_right ml-auto d-flex">
            <li class="call_and_mail">
              <a href="tel:09636-203040">
                <i class="bi bi-telephone-inbound-fill"></i>
              </a>
            </li>
            <li class="call_and_mail">
              <a href="mailto:mohasagor@gmail.com">
                <i class="bi bi-envelope"></i>
              </a>
            </li>

            <li>
              <a href="https://www.facebook.com/mymohasagor/" target="_blank">
                <i class="bi bi-facebook"></i>
              </a>
            </li>
            <li>
              <a href="https://twitter.com" target="_blank">
                <i class="bi bi-twitter"></i>
              </a>
            </li>
            <li>
              <a href="https://instagram.com/mohasagorltd/" target="_blank">
                <i class="bi bi-instagram"></i>
              </a>
            </li>
            <li class="merchant_display_on_mobile">
              <i class="bi bi-people-fill"></i>
            </li>
          </div>
        </div>
      </div>
      <!-- main header -->
      <div class="main-header">
        <div
          class="
            container-fluid
            d-flex
            justify-content-between
            align-items-center
          "
        >
          <div class="header-bottom-left d-flex align-items-center">
            <li id="toggle-menu" @click="menuShow">
              <i class="bi bi-list" id="__icon_fa_menu"></i>
            </li>
            <li>
              <a href="/">
                <img
                  :src="img_base_url + '/images/unnamed.gif'"
                  class="site-logo"
                />
              </a>
            </li>
            <li>
              <input
                type="text"
                class="search-input"
                @keyup="autoCompleteSearch"
                v-model="search"
                placeholder="What are you looking for?"
              />
              <button class="search-btn" @click="autoCompleteSearch">
                <i class="bi bi-search"></i>
              </button>
              <div class="search-content" v-if="search_products.length >= 1">
                <ul class="list-group">
                  <li
                    class="list-group-item"
                    v-for="(product, index) in search_products"
                    :key="index"
                  >
                    <a
                      :href="'/product/' + product.slug"
                      class="search-router-link"
                    >
                      <div class="__search_poruduct-details">
                        <div class="__search_img">
                          <img
                            :src="img_thumbnail_url + product.thumbnail_img"
                          />
                        </div>
                        <div class="__search_porducts_details">
                          {{ product.name + "-" + product.product_code }}

                          <p>
                            &#2547; {{ product.price }}
                            <small v-if="product.discount">
                              <del>&#2547; {{ product.sale_price }}</del>
                            </small>
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </div>
          <div class="header-bottom-right" style="margin-top:8px">
            <ul class="d-flex align-items-center" style="gap:6px">
              <router-link :to="{ name: 'Wishlist' }" class="merchant_display">
                <li class="fav_container" style="cursor:pointer">
                  <i class="bi bi-heart-fill header_icon"></i>
                  <p class="item_count">{{ wishlist.item_count }} </p>
                </li>
              </router-link>
              <li class="user_icon_desktop" v-if="Object.keys(user).length > 2">
                <div class="drop_item_containr">
                  <a @click="toggleUserMenu">
                    <strong v-if="user.name && user.name.length > 1">
                      {{ user.name }} <br />
                      {{ user.mobile_no }}
                    </strong>
                    <strong v-else>
                      {{ user.mobile_no }}
                    </strong>
                  </a>
                  <ul id="auth_u_menu" class="auth_user_menu">
                    <li>
                      <router-link :to="{ name: 'UserDashboard' }">
                        <i class="bi bi-list"></i> Dashboard</router-link
                      >
                    </li>
                    <li>
                      <router-link :to="{ name: 'PasswordEdit' }">
                        <i class="bi bi-key-fill"></i> Change
                        Password</router-link
                      >
                    </li>
                    <li>
                      <router-link :to="{ name: 'setNewPassword' }">
                        <i class="bi bi-key-fill"></i> Set New
                        Password</router-link
                      >
                    </li>
                    <li>
                      <a @click="Logout"> <i class="bi bi-power"></i> Logout</a>
                    </li>
                  </ul>
                </div>
              </li>
              <router-link class="user_icon_desktop" :to="{ name: 'otp_login' }" v-else>
                <li >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="user_icon">
                    <path fill-rule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clip-rule="evenodd" />
                  </svg>
                  
                </li>
              </router-link>
            </ul>
          </div>
        </div>
      </div>

      <!-- nav menu -->
      <div id="navbar" class="menu">
        <ul
          id="menu-list"
          class="menu-list"
          v-if="categories && categories.length > 0"
        >
          <!-- Category Li start  -->
          <li
            v-for="category in categories"
            :key="category.id"
            class="menu-item"
          >
            <router-link
              :to="{ name: 'category', params: { slug: category.slug } }"
              class="menu-item-link"
            >
              {{ category.name }}
            </router-link>

            <i
              class="bi bi-caret-down menu-icon"
              v-if="category.sub_category.length > 0"
            ></i>

            <!-- SubCategory UL start  -->
            <ul class="sub-item-list" v-if="category.sub_category.length > 0">
              <!-- SubCategory Li start  -->
              <li
                class="sub-item"
                v-for="sub_category in category.sub_category"
                :key="sub_category.id"
              >
                <router-link
                  :to="{
                    name: 'sub_category',
                    params: {
                      category: category.slug,
                      slug: sub_category.slug,
                    },
                  }"
                  class="sub-item-link"
                >
                  {{ sub_category.name }}
                </router-link>
                <i
                  class="bi bi-caret-down sub-menu-icon"
                  v-if="sub_category.sub_sub_category.length"
                ></i>

                <!--Sub Sub Category UL start  -->
                <ul
                  class="sub-sub-item-list"
                  v-if="sub_category.sub_sub_category.length"
                >
                  <!-- SubCategory LI start  -->
                  <li
                    class="sub-sub-item"
                    v-for="sub_sub_category in sub_category.sub_sub_category"
                    :key="sub_sub_category.id"
                  >
                    <router-link
                      :to="{
                        name: 'sub_sub_category',
                        params: {
                          category: category.slug,
                          sub_category: sub_category.slug,
                          slug: sub_sub_category.slug,
                        },
                      }"
                      class="sub-sub-item-link"
                    >
                      {{ sub_sub_category.name }}
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <!-- cart -->
    <div class="cart_container" id="s-cart">
      <!-- new header -->
      <div class="cart_header">
        <div class="item_info" id="exitcart">
          <i class="bi bi-bag-fill"></i>
          <h4>{{ cart.item_count }} Items</h4>
        </div>
        <i class="bi bi-x close_icon" @click="cartClosed"></i>
      </div>

      <!-- new Body -->
      <div class="product_info_container" v-if="cart.item_count > 0">
        <div
          class="cart_information"
          v-for="(item, index) in cart.contents"
          :key="index"
        >
          <!-- product image container -->
          <div class="cart_img_container">
            <img
              :src="img_thumbnail_url + item.product.thumbnail_img"
              class="cart_img"
            />
          </div>

          <!-- product details -->
          <div class="cart_details">
            <!-- product name, price, quantity and delete container -->
            <div class="name_price_container">
              <!-- product name & size container -->
              <div class="name_size_container">
                <h4 class="item_name">
                  {{ item.product.name.substring(0, 15).concat("...") }}
                </h4>
                <h6>&#2547;{{ item.product.price }}</h6>
              </div>

              <!-- product price container -->
              <div class="cart_total_container">
                <h4>
                  &#2547;
                  {{ parseInt(item.product.price) * parseInt(item.qty) }}
                </h4>
              </div>
            </div>
            <!-- product quantity container -->
            <div class="cart_qnt_container">
              <div class="qty_counter rounded-2">
                <i class="bi bi-dash px-2" @click="updateQuantity(item, 0)"></i>
                <h4 class="border_lr">{{ item.qty }}</h4>
                <i class="bi bi-plus px-2" @click="updateQuantity(item, 1)"></i>
              </div>
              <div class="delete_btn" @click="cartRemove(item.id)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="delete_icon"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- empty cart body -->
      <div v-else class="cart-body">
        <div class="cart-empy">
          <img :src="img_base_url + 'images/static/cartEmpty.jpg'" />
          <p>Your cart is empty</p>
        </div>
      </div>

      <!-- new Footer -->
      <!-- subtotal, checkout btn & continue shopping -->
      <div class="cart_checkout">
        <!-- total price & checkout btn -->
        <div class="subtotal_container">
          <div class="sub_total">
            <h4>Sub Total</h4>
            <h4>&#2547;{{ cart.total }}</h4>
          </div>
          <h6>shipping and discount calculated at checkout</h6>
          <router-link
            v-if="Object.keys(user).length"
            :to="{ name: 'Checkout' }"
          >
            <button class="cart_checkout_btn">Checkout</button>
          </router-link>
          <router-link :to="{ name: 'Checkout' }">
            <button class="cart_checkout_btn">Checkout</button>
          </router-link>
          <h6 class="more_shopping">
            <a href="/">
              Or <span @click="cartClosed">Continue Shopping</span>
            </a>
          </h6>
        </div>
      </div>
    </div>

    <!-- cart button -->
    <div class="cart-open" @click="cartOpen">
      <div class="cart-total">
        <i class="bi bi-bag-plus-fill"></i>
        <h5>{{ cart.total }}</h5>
      </div>
      <div class="cart-item-total">{{ cart.item_count }} items</div>
    </div>

    <!-- whats app -->
    <div id="WhatsApp" class="whats_app_float">
      <div id="cross_icon" class="cross" @click="closeWhatsApp">
        <i class="bi cross_icon bi-x"></i>
      </div>
      <a href="https://wa.me/+8801635212121">
        <svg
          fill="#fff"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30 30"
          width="30px"
          height="30px"
        >
          <path
            d="M 15 3 C 8.373 3 3 8.373 3 15 C 3 17.251208 3.6323415 19.350068 4.7109375 21.150391 L 3.1074219 27 L 9.0820312 25.431641 C 10.829354 26.425062 12.84649 27 15 27 C 21.627 27 27 21.627 27 15 C 27 8.373 21.627 3 15 3 z M 10.892578 9.4023438 C 11.087578 9.4023438 11.287937 9.4011562 11.460938 9.4101562 C 11.674938 9.4151563 11.907859 9.4308281 12.130859 9.9238281 C 12.395859 10.509828 12.972875 11.979906 13.046875 12.128906 C 13.120875 12.277906 13.173313 12.453437 13.070312 12.648438 C 12.972312 12.848437 12.921344 12.969484 12.777344 13.146484 C 12.628344 13.318484 12.465078 13.532109 12.330078 13.662109 C 12.181078 13.811109 12.027219 13.974484 12.199219 14.271484 C 12.371219 14.568484 12.968563 15.542125 13.851562 16.328125 C 14.986562 17.342125 15.944188 17.653734 16.242188 17.802734 C 16.540187 17.951734 16.712766 17.928516 16.884766 17.728516 C 17.061766 17.533516 17.628125 16.864406 17.828125 16.566406 C 18.023125 16.268406 18.222188 16.319969 18.492188 16.417969 C 18.766188 16.515969 20.227391 17.235766 20.525391 17.384766 C 20.823391 17.533766 21.01875 17.607516 21.09375 17.728516 C 21.17075 17.853516 21.170828 18.448578 20.923828 19.142578 C 20.676828 19.835578 19.463922 20.505734 18.919922 20.552734 C 18.370922 20.603734 17.858562 20.7995 15.351562 19.8125 C 12.327563 18.6215 10.420484 15.524219 10.271484 15.324219 C 10.122484 15.129219 9.0605469 13.713906 9.0605469 12.253906 C 9.0605469 10.788906 9.8286563 10.071437 10.097656 9.7734375 C 10.371656 9.4754375 10.692578 9.4023438 10.892578 9.4023438 z"
          />
        </svg>
      </a>
    </div>

    <!-- footer nav -->
    <div class="footer_nav">
      <ul class="mobile_nav_menu">
        <li>
          <a href="/">
            <div class="menu_option">
              <i class="bi bi-house-door-fill"></i>
              <span>Home</span>
            </div>
          </a>
        </li>
        <li>
          <a @click="cartOpen">
            <div class="menu_option">
              <i class="bi bi-cart-fill"></i>
              <p class="footer_cart_item_count">{{  cart.item_count  }} </p>
              <span>Cart</span>
            </div>
          </a>
        </li>
        <li>
          <router-link :to="{ name: 'Wishlist' }">
            <div class="menu_option">
              <i class="bi bi-heart-fill header_icon"></i>
              <p class="footer_item_count">{{ wishlist.item_count }} </p>
              <span>Wishlist</span>
            </div>
          </router-link>
        </li>
        <li v-if="Object.keys(user).length">
          <router-link :to="{ name: 'UserDashboard' }">
            <div class="menu_option">
              <i class="bi bi-person-fill"></i>
              <span>Profile</span>
            </div>
          </router-link>
        </li>
        <li v-else>
          <router-link :to="{ name: 'otp_login' }">
            <div class="menu_option">
              <i class="bi bi-person"></i>
              <span>Profile</span>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import _ from "lodash";

export default {
  name: "Header",
  mounted() {
    this.$store.dispatch("categories");
    this.$store.dispatch("cartContent");
    this.$store.dispatch("wishlistContent");
    setTimeout(() => {
      this.$store.dispatch("user");
    }, 1500);
  },
  computed: {
    categories() {
      return this.$store.state.categories;
    },
    cart() {
      return this.$store.getters.cartContent;
    },
    wishlist() {
      return this.$store.getters.wishlistContent;
    },
    user() {
      return this.$store.getters.user;
    },

  },
  data() {
    return {
      loading: true,
      search_products: [],
      search: null,
      isCartOpened: null,
    };
  },
  components: {},
  methods: {
    closeWhatsApp() {
      document.getElementById("WhatsApp").classList.add("hidden");
    },
    toggleUserMenu() {
      document
        .getElementById("auth_u_menu")
        .classList.toggle("toggle_auth_user_menu");
    },

    autoCompleteSearch: _.debounce(function() {
      if (this.search.length > 2) {
        this.axios.get("search/products/" + this.search).then((resp) => {
          console.log(resp);
          if (resp.data.success == true) {
            this.search_products.push(...resp.data.products);
          } else {
            this.search_products = [];
          }
        });
      } else {
        this.search_products = [];
      }
    }, 500),

    clearSearchingKeyword() {
      this.search = null;
      this.search_products = [];
    },

    Logout() {
      localStorage.removeItem("user_token");
      localStorage.removeItem("mDashboardReloaded");
      this.$router.push({ name: "Home" }).go();
    },

    cartOpen() {
      //this.isCartOpened = true
      document.getElementById("s-cart").classList.add("display");
    },
    cartClosed() {
      //    this.isCartOpened = false
      document.getElementById("s-cart").classList.remove("display");
    },

    async updateQuantity(item, type) {
      let quantity = 0;
      if (type == 1) {
        quantity = parseInt(item.qty) + parseInt(1);
      } else {
        quantity = parseInt(item.qty) - parseInt(1);
      }
      if (parseInt(quantity) <= 0) {
        this.$toast.warning("Quantity should be at least one");
        quantity = 1;
        return;
      }

      await this.axios
        .post("cart/item/update", {
          header: this.$apiHeader,
          qty: quantity,
          id: item.id,
        })
        .then((resp) => {
          if (resp.data.success == true) {
            this.$toast.success(resp.data.message);
            this.$store.dispatch("cartContent");
          }
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        });
    },

    async cartRemove(id) {
      await this.axios
        .post("cart/item/remove", {
          headers: this.$apiHeader,
          id: id,
        })
        .then((resp) => {
          console.log(resp);
          if (resp.data.success == true) {
            this.$store.dispatch("cartContent");
            this.$toast.error(resp.data.message);
          }
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        });
    },

    menuShow() {
      document.getElementById("toggle-menu");
      let main_menu = document.getElementById("menu-list");
      main_menu.classList.toggle("collapse-manu");
      let menu_icon = document.getElementById("__icon_fa_menu");

      if (menu_icon.classList.contains("bi-list")) {
        menu_icon.classList.remove("bi-list");
        menu_icon.classList.add("bi-x-circle");
      } else {
        menu_icon.classList.add("bi-list");
        menu_icon.classList.remove("bi-x-circle");
      }
    },

    showNextElement(e) {
      let target_element = e.target;
      let sub_menu_ul = target_element.nextSibling.nextElementSibling;
      sub_menu_ul.classList.toggle("show");
      target_element.classList.toggle("rotate");
    },

    optionClick(slugs) {
      document.getElementById("toggle-menu");
      let main_menu = document.getElementById("menu-list");
      main_menu.classList.toggle("collapse-manu");
      let menu_icon = document.getElementById("__icon_fa_menu");

      if (menu_icon.classList.contains("bi-list")) {
        menu_icon.classList.remove("bi-x-circle");
        menu_icon.classList.add("bi-list");
      } else {
        menu_icon.classList.add("bi-x-circle");
        menu_icon.classList.remove("bi-list");
      }
      window.location.assign(document.location.origin + "/" + slugs);
    },

    documentClick() {
      document.getElementById("s-cart").classList.remove("colapse-cart");
    },
    isCartClosed() {
      const body = document.body;
      body.addEventListener("click", () => {
        if (this.isCartOpened == null) {
          this.isCartOpened = true;
        } else {
          if (this.isCartOpened) {
            this.isCartOpened = null;
            document.getElementById("s-cart").classList.remove("colapse-cart");
          } else {
            this.isCartOpened = true;
            document.getElementById("s-cart").classList.add("colapse-cart");
          }
        }
      });
    },
  },
  created() {
    this.isCartClosed();
  },
  watch: {
    categories: function() {
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.header-bottom-right li{
  height: 44px;
width: 44px;
display: flex;
padding: 8px;
justify-content: center;
align-items: center;
}

.nav-active {
  display: block !important;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999999999999;
}
.q-i-d {
  cursor: pointer;
}
ul.p-image-name li {
  padding: 0px 2px;
  font-size: 12px;
}


@media screen and (max-width: 450px) {
  .auto_writer {
    top: 104px;
    left: 5%;
    font-size: 11px;
  }
}

/* Max-width 320px start  */

@media screen and (max-width: 320px) {
  .clearSearch {
    top: 100%;
  }
}

/* Max-width 320px End */

.clearSearch {
  position: absolute;
  z-index: 1000;
  top: 279%;
  width: 48%;
  max-height: auto;
  overflow-y: scroll;
  background: #fff;
  min-height: auto;
  margin-left: 30px;
}

.clearSearchKeyword {
  display: block;
  width: 100%;
}

/* Menu Style with Ripon  */

@media screen and (max-width: 768px) {
  ul#menu-list {
    display: flex;
    align-items: normal;
    width: 75%;
    margin-top: -38px;
  }
}
/* Menu Style with Ripon End */
</style>
