<template>
    <footer id="footer">
      <div class="fpart-first">
        <div class="container-fluid">
          <div class="row" style="justify-content: space-between;">
            <div class="contact col-lg-3 col-md-3 col-sm-12 col-xs-12">
              <ul>
                <li class="address">
                  <p class="short_desc">
                    <span
                      style="
                        font-size: 16px;
                        color: rgb(255, 77, 3);
                        font-weight: 700;
                      "
                      >Mohasagor.com</span
                    >
                    is a complete e-commerce website in Bengali and English.
                    Here, consumers of all ages can buy every essential product
                    of the day, from gadgets, electronics, home appliances,
                    leather goods, jewelry, baby accessories, cosmetics, fashion
                    and lifestyle products to affordable prices at home
                  </p>
                </li>
              </ul>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
             <div class="quick_link_container">
                <div class="quick_link">
                  <h5>Quick Links</h5>
                  <div class="line"></div>
                  <ul class="link_line">
                    <li><router-link to="/" class="router-link-active">Home</router-link></li>
                    <li><router-link to="/about" class="">About Us</router-link></li>
                    <li><router-link to="/contact/us" class="">Contact Us</router-link></li>
                  </ul>
                </div>

              <div class="quick_link">
                <h5>Information</h5>
                <div class="line"></div>
                <ul class="link_line">
                  <li><a href="/how/to/buy" class="">How to order</a></li>
                  <li><a href="/return/policy" class="">Return Policy</a></li>
                  <li><a href="/shipment" class="">Shipment </a></li>
                  <li><a href="/team" class="">Team</a></li>
                </ul>
              </div>

             </div>
            </div>

            <div class=" col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div class="news_letter">
                <h5>Follow Us</h5>
                <div class="line"></div>
                <form>
                  <div class="row">

                    <div class="social_container">
                      <iframe
                        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fmymohasagor%2F&amp;tabs=timeline&amp;width=340&amp;height=150&amp;small_header=true&amp;adapt_container_width=false&amp;hide_cover=false&amp;show_facepile=false&amp;appId=112664817367714"
                        width="340"
                        height="150"
                        scrolling="no"
                        frameborder="0"
                        allowfullscreen="allowfullscreen"
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                        style="border: none; overflow: hidden"
                      ></iframe>
                      <div class="social-icon" >
                        <a
                          href="https://www.youtube.com/channel/UCUaEOS7b8kcz47YAxJIUL2w"
                          target="_blank"
                          class="social-wrape"
                          ><i class="bi bi-youtube f-icon"></i >

                          </a>
                        <a
                          href="#"
                          target="_blank"
                          class="social-wrape"
                          style="margin-top: 10px"
                          >
                          <i class="bi bi-twitter f-icon"></i>
                          </a>
                        <a
                          href="https://www.instagram.com/mohasagorltd/"
                          target="_blank"
                          class="social-wrape"
                          style="margin-top: 10px"
                          ><i class="bi bi-instagram f-icon"></i></a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fpart-second">
        <div class="container">
          <div id="powered" class="clearfix"></div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="payment-card">
                <img
                  :src="img_base_url+'images/ssl.png'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

        <div class="row">
          <div class="end_footer">
            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
              <p class="f_info_left">
                Copyright © mohasagor.com 2020. All rights reserved.
              </p>
            </div>
            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
              <p class="f_info_right">
               Developed by: <a href="https://mohasagorit.solutions/" target="_blank"> Mohasagor it Solution </a>
              </p>
            </div>
          </div>
        </div>

    </footer>
</template>


<script>
export default {
  data(){
     return{
      email:''
     }
  },
  methods:{
       subscribe(){
         this.axios.post('add/subscriber',{
             email: this.email ,
         })
         .then((resp)=>{
           console.log(resp);
            if (resp.data.success == "OK") {
            this.email = "";
            this.$toast.success(resp.data.message);
          } else {
            this.email = "";
              this.$toast.error(resp.data.message);
          }
         })
       }
  }
}
</script>

<style scoped>


#footer .fpart-first {
    background-color: #191919;
}

#footer .fpart-first {
    padding-top: 50px;
    padding-bottom: 10px;
}

#footer .fpart-second {
    color: #666;
    background-color: #111;
}

#footer h5 {
    color: #fff;
}


#footer .fpart-first a {
    color: #999;
}


.short_desc {
 color: #999;
  font-size: 14px;
  line-height: 24px;
}

.payment-card {
  padding-top: 20px;
}

.payment-card img {
  width:100% ;
  height: auto ;
}


 .quick_link_container {
    display: flex;
  }


 .quick_link {
    margin: 0px 25px;
  }



.link_line li{
    padding: 6px 0px;
    width: 100%;
    border-bottom: 1px solid rgb(56, 53, 53);
    display: -webkit-flex;
    display: flex;
    transition: all .2s ease;
}

.link_line li:hover {
    background-color: #2a2b2b;
    padding-left: 0.275rem;
}


.line {
  width: 72px;
  height: 2px;
  background: #ff4d03;
  margin-bottom: 12px;
}

.subscribe_container{
  display: flex;
  box-shadow: 0 1pt 12pt rgb(150 165 237);
  padding: 10px 0px;
  margin-bottom: 10px;
}

.s_form {
  width: 360px;
}

.email_btn {
    width: 58px;
    height: 38px;
    background: #ff4d03;
    margin-top: -38px;
    border: none;
    float: right;
}

.email_btn i
{
    color: #fff;
    font-size: 26px;
}

.social_container{
  display: flex;
  margin: 30px 0px;
  box-shadow: 0 1pt 12pt rgb(150 165 237);
  padding: 5px 0px;
}

.social-icon {
  display: flex;
  flex-direction: column;
  margin-top: 7px;
  margin: 10px;
}

.social-wrape {
  float: left;
  width: 40px;
  height: 40px;
  border: 2px dashed;
  border-radius: 50%;
  margin-left: 10px;
}

.social-wrape:hover {
  background: #ff4d03;
}

.social-wrape:hover > i {
  color: #fff ;
}


.f-icon {
    font-size: 26px;
    cursor: pointer;
    color: #ff4d03;
    position: absolute;
    margin: 6px 6px;
}


.end_footer {
  display: flex;
  background: #ff4d03;
  height: 50px;
  width: 100%;
}

.f_info_left {
  margin-left: 70px !important;
  color: #fff;
  margin-top: 20px;
  margin-left: 5px;
  font-family: serif;
  font-size: 16px;
}

.f_info_right {
  float: right;
  color: #fff;
  margin-top: 20px;
  margin-left: 120px;
  font-size: 16px;
  font-family: serif;
  margin-right: 20px ;
}




 @media only screen and (max-width:768px) {

   .s_form {
      width: 330px;
    }

   .social-wrape {
    margin-left: 12px;
    margin-top: 10px;
   }

   .container{
    margin: 0px 0px;
  }

   .social_container{
    margin: 60px 0px;
  }

   .social-icon {
    display: block;
    margin: 45% 20%;
    position: absolute;
  }

  

  .end_footer {
    display: none;
  }

    .short_desc {
        padding: 5px;
    }

    .news_letter {
      padding:0px 20px;
    }

    .payment-card {
        padding-top: 20px;
        margin-top: -20px;
        margin-bottom: 35px;
    }



  }





</style>
