<template>
  <div>
    <div
      v-if="quick_view_modal"
      style="display: block"
      class="modal modal_overlay"
    >
      <!-- new model -->
      <div class="model_container">
        <div class="model_box">
          <!-- header and cross section -->
          <div class="cross_btn" @click="closeModal">
            <button class="btn_cancel"><i class="bi bi-x"></i></button>
          </div>
          <!-- all information container -->
          <div class="information_container">
            <!-- all image options -->
            <div class="image_options">
              <img
                v-for="(image, index) in quick_view_product.product_image"
                :key="index"
                :class="{ __p_active: index == 0 }"
                :src="img_base_url + image.product_image"
                @click="display_image_from_box"
                class="option_img"
              />
            </div>
            <!-- preview image -->
            <div class="preview_image">
              <img class="img_preview" v-if="img_preview" :src="img_preview" />
              <img
                class="img_preview"
                v-else
                :src="
                  img_base_url +
                  quick_view_product.product_image[0].product_image
                "
              />
            </div>
            <!-- name code and details  -->
            <div class="product_q_details">
              <!-- product name -->
              <h3>{{ quick_view_product.name }}</h3>
              <!-- product code -->
              <h5>Code: <span>{{ quick_view_product.product_code }}</span></h5>
              <!-- price -->
              <div class="price_discount_container">
                <h5 style="margin-bottom: 0">Price:</h5>
                <!-- sale price -->
                <h4 class="new_price">&#2547;{{ quick_view_product.price }}</h4>
                <!-- actual price -->
                <h4
                class="old_price"
                  v-if="
                    quick_view_product.discount > 0 &&
                    quick_view_product.price != quick_view_product.sale_price
                  "
                >
                  &#2547;{{ quick_view_product.sale_price }}
                </h4>
                <!-- label -->
                <h4
                  class="offer_label"
                  v-if="
                    quick_view_product.discount > 0 &&
                    quick_view_product.price != quick_view_product.sale_price
                  "
                >
                  {{
                    (
                      (quick_view_product.discount /
                        quick_view_product.sale_price) *
                      100
                    ).toFixed(0)
                  }}% Off
                </h4>
              </div>
              <!-- size container -->
              <div
                class="size_container"
                v-if="
                  quick_view_product.product_attribute &&
                  quick_view_product.product_attribute.attribute.name.toLowerCase() ==
                    'size'
                "
              >
                <h4>Available Size:</h4>
                <ul class="size_list">
                  <li
                    v-for="(
                      variant, index
                    ) in quick_view_product.product_variant"
                    :key="index"
                  >
                    <input
                      class="size_input"
                      type="radio"
                      :id="variant.variant.id"
                      name="size"
                      :value="variant.variant.id"
                      v-model="cart.variant_id"
                    />
                    <label :for="variant.variant.id">{{
                      variant.variant.name
                    }}</label>
                  </li>
                </ul>
              </div>
              <!-- qty container -->
              <div class="qty_container">
                <div class="qty_box">
                  <div @click="incrementQty" class="incrementor_minus">
                    <i class="bi bi-bag-dash"></i>
                  </div>
                  
                  <input
                    type="text"
                    name="qty"
                    v-model="cart.qty"
                    value="1"
                    class="form-control qty_input"
                    @change="validation"
                    @keyup="validation"
                  />
                  <div @click="decrementQty" class="incrementor_plus">
                    <i class="bi bi-bag-plus"></i>
                  </div>
                </div>
              </div>
              <!-- Add to cart button -->
              <div class="add_cart_container">
                <button
                  @click.prevent="addToCart(quick_view_product,1)"
                  type="button"
                  class="btn btn-sm btn-primary quick_cart_btn"
                >
                <i class="bi bi-bag"></i> Add to Cart
                </button>
                <button
                  @click.prevent="addToCart(quick_view_product,2)"
                  type="button"
                  class="btn btn-sm btn-primary quick_cart_btn"
                >
                <i class="bi bi-bag"></i> Buy Now
                </button>
              </div>
              <!-- product details container -->
              <div class="product_details">
                <h4>Product Details:</h4>
                <p
                  class="quick_description"
                  v-html="quick_view_product.details"
                ></p>
              </div>
            </div>
          </div>
          <!-- related products -->
          <div class="row related_quick_row">
            <div class="r_quick_heading text-center">
              <h4 class="r_header">Recommended Products</h4>
            </div>
            <div class="r_quick_body">
              <div
                v-for="(r_product, index) in recommended_products"
                :key="index"
                class="rendom_product_item text-center"
              >
              <div class="related_product_img_container">
                  <img
                  :src="img_thumbnail_url + r_product.thumbnail_img"
                  class="img-responsive r_p_image"
                />
              </div>
                
                <a class="r_p_title" @mouseover="recommended_replace(index)"
                  >{{ r_product.name }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "quick_view_modal",
  created() {
    this.productQuickView();
  },
  props: ["quick_v_p_id"],

  data() {
    return {
      img_preview: "",
      isLoading: true,
      fullPage: true,
      quick_loading: false,
      disabled: true,
      variant_index: "",
      cart: {
        variant_id: "",
        qty: 1,
        firstSizeChecked: null,
      },
      quick_view_product: "",
      quick_view_modal: false,
      recommended_products: "",
    };
  },

  methods: {
    // image preview
    display_image_from_box(e) {
      let target_element = e.target;
      let active_images = document.getElementsByClassName("__p_active");

      if (active_images.length > 0) {
        for (let i = 0; i < active_images.length; i++) {
          active_images[i].classList.remove("__p_active");
        }
      }
      target_element.classList.add("__p_active");
      this.img_preview = target_element.src;
      console.log(this.img_preview);
    },

    incrementQty() {
      if (this.cart.qty > 1) {
        let qty = this.cart.qty;
        this.cart.qty = qty - 1;
      }
    },
    decrementQty() {
      let qty = this.cart.qty;
      this.cart.qty = qty + 1;
    },
    
    productQuickView() {
      this.$Progress.start();
      this.axios
        .get("get/quick/view/product/" + this.quick_v_p_id,{
          headers: this.$apiHeader
        })
        .then((resp) => {
          console.log(resp);
          if (resp.data.success == true ) {
            this.quick_view_product = resp.data.product;
            this.recommended_products = resp.data.recommended_products;
            this.quick_view_modal = true;
            this.quick_loading = false;
          }
          this.$Progress.finish();
          this.isLoading = false;
        })
        .catch(error=>{
          this.$toast.error(error.response.data.message);
        })
    },

    recommended_replace(index) {
      this.$Progress.start();
      this.quick_view_product = this.recommended_products[index];
      console.log(this.recommended_products[index]);
      this.$Progress.finish();
    },

    closeModal() {
      this.quick_view_modal = false;
      this.$emit("clicked");
    },

    async addToCart(product,type) {
      //checking if product has attribute
      if (product.product_variant.length > 0) {
        if (this.cart.variant_id < 1) {
          this.$toast.error(
            "please,select product " +
              product.product_attribute.attribute.name
          );
          return;
        }
      }

    await  this.axios
        .post("add/to/cart", {
          headers: this.$apiHeader,
          slug: product.slug,
          qty: this.cart.qty,
          variant_id: this.cart.variant_id,
        })
        .then((resp) => {
          // console.log(resp);
          if (resp.data.success ==true) {
            if (type == 1) {
              this.$store.dispatch("cartContent");
            } else {
              this.$router.push({ name: "Checkout" });
            }
            this.$toast.success(resp.data.message);
          } 
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        });
    },



    validation() {
      if (this.cart.qty < 1) {
        this.cart.qty = 1;
        alert("qty at least 1");
        return;
      }
      if (this.quick_view_product.product_attribute) {
        if (this.cart.attribute_id < 1) {
          this.disabled = true;
        } else {
          this.disabled = false;
        }
      } else {
        this.disabled = false;
      }
    },
  },
};
</script>

<style scoped>
ol,
ul,
dl,
p {
  margin: 0;
}
.details_container {
  margin-top: 16px;
}
.quick_description li {
  margin-bottom: 4px;
}
.modal_overlay {
  background: #00000073;
}

.quick_p_info {
  margin-top: 16px;
}

.price_section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.price_section h4 {
  font-size: 20px;
  font-weight: bold;
  font-family: serif;
  margin: 0;
}

.q_p_price {
  font-size: 18px;
  gap: 6px;
  display: flex;
  align-items: center;
}


.quick_p_info li:not(:first-child) {
  text-align: left;
  padding: 5px 0px !important;
}

.quick_view_image {
  width: 100%;
}
.single_p_name {
  font-size: 22px;
}
.single_p_code {
  font-size: 20px;
}

.close_sign {
  font-size: 24px;
  color: #000;
  border: none;
  padding: 6px 10px;
  cursor: pointer;
}

.rendom_product_item {
  float: left;
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2%;
}

.animator {
  margin-top: 100px;
  padding: 100px;
}

.animator i {
  font-size: 70px;
}

.modal-body h4 p {
  margin-left: 20px;
}

.modal-dialog {
  transition: 0.3s;
}

.quick_content {
  background: #fff;
}
.quick_description {
  overflow-y: auto;
  font-size: 14px;
  color: rgb(89, 89, 89);
}

.r_p_title {
  font-size: 12px;
  width: 60%;
}
.related_product_img_container{
  height: 150px;
}

.r_p_image {
  width: 155px;
  object-fit: cover;
  object-position: center;
  height: 100%;
  border-radius: 5px;
}

.r_p_button {
  margin-left: 35px;
  border-radius: 5px;
}

.r_p_button:hover {
  border-radius: 50px;
  background: #db3700;
}
.related_quick_row {
  margin: 0px;
  max-height: 248px;
  overflow-y: scroll;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
.related_quick_row {
  scrollbar-width: none;
  scrollbar-color: #000000 #ffffff;
}

/* Chrome, Edge, and Safari */
.related_quick_row::-webkit-scrollbar {
  width: 8px;
}

.related_quick_row::-webkit-scrollbar-track {
  background: #ffffff;
}

.related_quick_row::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 10px;
  border: 3px solid #ffffff;
}
.r_quick_body {
  padding: 5px 10px;
  height: 240px;
}

.col-md-2 {
  margin-bottom: 25px;
}

@media only screen and (max-width: 960px){
  .rendom_product_item {
    width: 25%;
    height: 240px;
  }

}
@media only screen and (max-width: 768px){
  .rendom_product_item {
    width: 33.33%;
    height: 240px;
  }
}
@media only screen and (max-width: 590px) {
  .rendom_product_item {
    width: 50%;
    height: 240px;
  }
  .r_p_image {
    width: 130px;
    border-radius: 5px;
  }
}
</style>
