import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store"
import axios from 'axios';
import { Skeleton } from "vue-loading-skeleton";
import {  HasError } from "vform";
require('./devToolOff');
import InfiniteLoading from 'vue-infinite-loading';
Vue.use(InfiniteLoading, { /* options */ });

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const sweetAlertOptions = {
  confirmButtonColor: '#ff4d03',
  cancelButtonColor: '#ff7674',
};
Vue.use(VueSweetalert2, sweetAlertOptions);


const progressBarOptions = {
  color: '#bffaf3',
  failedColor: '#874b4b',
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'left',
  inverse: false
}
import VueProgressBar from 'vue-progressbar'
Vue.use(VueProgressBar, progressBarOptions)
Vue.component('Skeleton',Skeleton)
Vue.component('has-error',HasError)

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
Vue.use(Toast);
Vue.prototype.img_thumbnail_url = 'https://admin.mohasagor.com/public/storage/images/product_thumbnail_img/'
Vue.prototype.img_base_url = 'https://admin.mohasagor.com/public/storage/'
axios.defaults.baseURL = 'https://admin.mohasagor.com/api/_data/'
axios.defaults.headers.common['x-api-key'] = "$2y$10$kzaYH2t9fgVMoyqKMWF7POjD45cilj";

Vue.config.devtools = true;
Vue.prototype.axios = axios
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')


