<template>
  <div class="home_products_container">
    <div class="" v-for="(product, index) in products" :key="index">
      <div class="product-card">
        <div class="prodict-card-body">
          <router-link
            :to="{ name: 'product', params: { slug: product.slug } }"
          >
            <div class="product-image">
              <img :src="img_thumbnail_url + product.thumbnail_img" />
            </div>
          </router-link>

          <div class="product-detail">
            <h4>
              <router-link
                class="product-link"
                :to="{ name: 'product', params: { slug: product.slug } }"
              >
                {{ product.name.substring(0, 25) }}
                <span v-if="product.name.length > 25">...</span>
              </router-link>
            </h4>
            <p class="price">
              <span class="price-new" :class="{}"> {{ product.price }}</span>
              <span
                class="price-old"
                v-if="
                  product.discount > 0 && product.price != product.sale_price
                "
                >{{ product.sale_price }}</span
              >
              <span class="discount_percentage" v-if="product.discount > 0"
                >{{
                  ((product.discount / product.sale_price) * 100).toFixed(0)
                }}% Off</span
              >
            </p>
          </div>
        </div>

        <div class="product-card-footer">
          <button
            class="btn btn-sm btn-primary btn btnQuick"
            style="cursor: pointer"
            @click="quick_v_product_id = product.id"
          >
            Buy now
          </button>
          <!-- add to cart -->
          <div @click.prevent="addToWishlist(product.slug)" class="fav_btn">
            <i class="bi bi-heart"></i>
          </div>
        </div>
      </div>
    </div>
    <quickView
      v-if="quick_v_product_id"
      v-on:clicked="closedModal($event)"
      :quick_v_p_id="quick_v_product_id"
    />
  </div>
</template>
<script>
import quickView from "./QuickView.vue";

export default {
  props: {
    products: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      quick_v_product_id: "",
    };
  },
  components: {
    quickView,
  },
  methods: {
    closedModal() {
      this.quick_v_product_id = "";
    },

    async addToWishlist(slug) {
      console.log('clicked');
      await this.axios
        .post("add/to/wishlist", {
          headers: this.$apiHeader,
          slug: slug,
        })
        .then((resp) => {
          console.log(resp);
          if (resp.data.success == true) {
            this.$store.dispatch("wishlistContent");
            this.$toast.success(resp.data.message);
          }
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        });
    },
  },
};
</script>
