<template>
  <div id="app">
    <Header/>
    <router-view :key="$route.fullPath" />
    <Footer/>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/stylesheet.css" ;
import "./assets/css/newstyle.css" ;
import "./assets/css/header.css";
import "./assets/css/customize.css"
import "./assets/css/CategoryProduct.css"

export default {
  name: "Home",
  components: {
    Header,
    Footer
  },

};
</script>
<style>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");

li {
  list-style: none;
}
* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: #000 ;
}
body {
  background: #f7f8fa;
}
.container-fluid {
  width: 94%;
}
.header {
  position: relative;
  width: 100%;
}

.header-top {
  width: 100%;
  height: 29px;
  display: flex;
  background: #f7f8fa;
  border-bottom: 1px solid #ddd;
}
.header-top li {
  padding: 5px 4px;
  font-size: 14px;
}
.primary-color {
  color: #ff4d03;
}
.primary-bg {
  background: #ff4d03;
}
.header_left.d-flex li {
  padding: 0px 12px;
}
.header_right.ml-auto.d-flex li {
  padding: 0px 15px;
  font-size: 16px;
}
.main-header {
  background: #fff;
}

.main-header li {
  padding: 5px 4px;
  font-size: 14px;
}

img.site-logo {
  max-width: 180px;
  max-height: 60px;
}




.header-bottom-right {
  margin-right: 0px;
  margin-top: 14px;
}

.header-bottom-right li {
  padding: 8px 13px;
  /* border-bottom: 2px solid #000; */
  margin-right: 10px;
  background: #fff;
  box-shadow: 3px 3px 3px #ddd;
  border-radius: 8px;
  font-weight: 600;
}
.__loading {
  position: fixed;
  background: #fff;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.f-s-18{
  font-size: 18px;
}
.f-s-16{
  font-size: 16px;
}
.f-w-600{
  font-weight: 600;
}
.f-w-700{
  font-weight: 700;
}

a.offer {
    padding: 13px;
    text-align: center;
    text-transform: capitalize;
}

a.offer img {
    padding: 15px;
    border-radius: 20px;
    border: 1px solid #ff4d03;
    max-width: 180px;
}


</style>
