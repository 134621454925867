var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('Slider'),_c('Offer'),_c('TopProducts'),(_vm.landing_sub_categories)?_c('div',_vm._l((_vm.landing_sub_categories),function(sub_category,idx){return _c('div',{key:idx,staticClass:"c-product"},[(sub_category.products && sub_category.products.length)?_c('div',{staticClass:"c-product-header"},[_c('h4',{staticClass:"category-heading"},[_vm._v(_vm._s(sub_category.name))]),_c('h4',{staticClass:"category-heading-mobile",on:{"click":function($event){return _vm.toggleSubCategory(sub_category.id)}}},[_vm._v(" "+_vm._s(sub_category.name)+" "),_c('i',{staticClass:"bi bi-list-ul angle_icon_on_mobile"})]),(
            sub_category.sub_sub_category &&
              sub_category.sub_sub_category.length > 0
          )?_c('div',{staticClass:"s-category",attrs:{"id":'s_category_mobile_' + sub_category.id}},[_c('div',{staticClass:"sub_category_view",attrs:{"id":'subCategoryNameView' + sub_category.id}},[_vm._l((sub_category.sub_sub_category),function(sub_sub_category,index){return _c('router-link',{key:index,staticClass:"sub-category-name",attrs:{"to":{
                name: 'sub_sub_category',
                params: {
                  category: sub_category.category.slug,
                  sub_category: sub_category.slug,
                  slug: sub_sub_category.slug,
                },
              }}},[_vm._v(_vm._s(sub_sub_category.name))])}),_c('router-link',{staticClass:"c-v-all",attrs:{"to":{
                name: 'sub_category',
                params: {
                  category: sub_category.category.slug,
                  slug: sub_category.slug,
                },
              }}},[_vm._v("View All ")])],2)]):_vm._e()]):_vm._e(),_c('Products',{attrs:{"products":sub_category.products}})],1)}),0):_vm._e(),_c('InfiniteLoading',{on:{"distance":function($event){0.5},"infinite":_vm.getCategoryProducts}},[_c('div',{attrs:{"slot":"no-more"},slot:"no-more"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }