import disableDevtool from 'disable-devtool';

if (process.env.NODE_ENV !== 'development') {
    disableDevtool();
    document.addEventListener('contextmenu', function (e) {
        e.preventDefault();
    });
    document.addEventListener('keydown', function (e) {
        if (e.keyCode == 123) {
            e.preventDefault();
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
            e.preventDefault();
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
            e.preventDefault();
        }
        if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
            e.preventDefault();
        }
    });
}