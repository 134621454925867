import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
// import axios from "axios"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },

  {
    path: "/checkout",
    component: () =>
      import(/* webpackChunkName: "Checkout" */ "../views/Checkout.vue"),
    name: "Checkout",
    meta: {
      title: "order checkout",
    },
  },
  {
    path: "/wishlist",
    component: () =>
      import(/* webpackChunkName: "Checkout" */ "../views/Wishlist.vue"),
    name: "Wishlist",
    meta: {
      title: "Wishlist",
    },
  },

  {
    path: "/user/Checkout/success",
    component: () =>
      import(
        /* webpackChunkName: "order_success" */ "../views/OrderSuccess.vue"
      ),
    name: "order_success",
    meta: {
      title: "order success",
    },
  },

  {
    path: "/campaigns",
    component: () =>
      import(/* webpackChunkName: "campaigns" */ "../views/Campaigns.vue"),
    name: "campaigns",
    meta: {
      title: "campaigns",
    },
  },

  {
    path: "/login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/user/Login.vue"),
    name: "login",
    meta: {
      title: "user login",
    },
  },
  {
    path: "/register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/user/Register.vue"),
    name: "register",
    meta: {
      title: "user register",
    },
  },

  {
    path: "/otp/login",
    component: () =>
      import(/* webpackChunkName: "otp_login" */ "../views/user/OTPLogin.vue"),
    name: "otp_login",
    meta: {
      title: "login by otp",
    },
  },

  {
    path: "/carrier",
    component: () =>
      import(/* webpackChunkName: "carrier" */ "../views/carrier/index.vue"),
    name: "carrier",
    meta: {
      title: "Carrier ",
    },
  },

  {
    path: "/carrier/details/:id",
    component: () =>
      import(
        /* webpackChunkName: "carrier_details" */ "../views/carrier/CarrierDetails.vue"
      ),
    name: "carrier_details",
    meta: {
      title: "Carrier Details ",
    },
  },

  {
    path: "/user/dashboard",
    component: () =>
      import(
        /* webpackChunkName: "user_dashboard" */ "../views/user/Dashboard.vue"
      ),
    name: "UserDashboard",
    meta: {
      title: "Customer Dashboard ",
      requiresAuthUser: true,
    },
  },

  {
    path: "/customer/order/:id",
    name: "order_details",
    component: () =>
      import(
        /* webpackChunkName: "order_details" */ "../views/user/OrderDetails.vue"
      ),
    meta: {
      title: "Customer Order Details ",
      requiresAuthUser: true,
    },
  },
  {
    path: "/user/profile",
    component: () =>
      import(/* webpackChunkName: "UserProfile" */ "../views/user/Profile.vue"),
    name: "UserProfile",
    meta: {
      title: "Profile Information ",
      requiresAuthUser: true,
    },
  },

  {
    path: "/user/profile/info/edit",
    component: () =>
      import(
        /* webpackChunkName: "UserProfileEdit" */ "../views/user/Edit.vue"
      ),
    name: "UserProfileEdit",
    meta: {
      title: "Profile Information Change ",
      requiresAuthUser: true,
    },
  },

  {
    path: "/user/password",
    component: () =>
      import(
        /* webpackChunkName: "user_password" */ "../views/user/Password.vue"
      ),
    name: "PasswordEdit",
    meta: {
      title: "password || Update",
      requiresAuthUser: true,
    },
  },

  {
    path: "/user/set/password",
    component: () =>
      import(
        /* webpackChunkName: "setNewPassword" */ "../views/user/SetNewPassword.vue"
      ),
    name: "setNewPassword",
    meta: {
      title: "set  password",
      requiresAuthUser: true,
    },
  },

  {
    path: "/about/seller",
    name: "about_seller",
    component: () =>
      import(
        /* webpackChunkName: "about_seller" */ "../views/policy_and_info/AboutSeller.vue"
      ),

    meta: {
      title: " About Seller",
    },
  },

  {
    path: "/how/to/buy",
    name: "how_to_buy",
    component: () =>
      import(
        /* webpackChunkName: "how_to_buy" */ "../views/policy_and_info/HowToBuy.vue"
      ),
    meta: {
      title: "How To Buy || Product ",
    },
  },

  {
    path: "/return/policy",
    name: "return_policy",
    component: () =>
      import(
        /* webpackChunkName: "return_policy" */ "../views/policy_and_info/ReturnPolicy.vue"
      ),
    meta: {
      title: " Return || Policy ",
    },
  },

  {
    path: "/shipment",
    component: () =>
      import(
        /* webpackChunkName: "shipment" */ "../views/policy_and_info/Shipment.vue"
      ),
    name: "shipment",
    meta: {
      title: "shipment ",
    },
  },

  {
    path: "/team",
    component: () =>
      import(
        /* webpackChunkName: "team" */ "../views/policy_and_info/Team.vue"
      ),
    name: "team",
    meta: {
      title: " Team",
    },
  },
  {
    path: "/about/us",
    component: () =>
      import(
        /* webpackChunkName: "about_us" */ "../views/policy_and_info/Aboutus.vue"
      ),
    name: "AboutUs",

    meta: {
      title: "About||us",
    },
  },

  {
    path: "/contact/us",
    component: () =>
      import(
        /* webpackChunkName: "contact_us" */ "../views/policy_and_info/Contactus.vue"
      ),
    name: "ContactUs",
    meta: {
      title: "Contact||us",
    },
  },

  {
    path: "/product/:slug",
    component: () =>
      import(/* webpackChunkName: "product" */ "../views/Product.vue"),
    name: "product",
    meta: {
      title: "Product Details",
    },
  },

  {
    path: "/campaign/:slug",
    component: () =>
      import(/* webpackChunkName: "campaign" */ "../views/Campaign.vue"),
    name: "campaign",
    meta: {
      title: "campaign",
    },
  },

  {
    path: "/category/:category/:sub_category/:slug",
    component: () =>
      import(
        /* webpackChunkName: "sub_sub_category" */ "../views/SubSubCategory.vue"
      ),
    name: "sub_sub_category",
    meta: {
      title: "Sub Sub Category  Products",
    },
  },

  {
    path: "/category/:category/:slug",
    component: () =>
      import(/* webpackChunkName: "sub_category" */ "../views/SubCategory.vue"),
    name: "sub_category",
    meta: {
      title: "Sub Category  Products",
    },
  },

  {
    path: "/category/:slug",
    component: () =>
      import(/* webpackChunkName: "category" */ "../views/Category.vue"),
    name: "category",
    meta: {
      title: "Category  Products",
    },
  },

  {
    path: "*",
    name: "not_found",
    component: () =>
      import(/* webpackChunkName: "not_found"*/ "../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);

  if (to.matched.some((record) => record.meta.requiresAuthUser)) {
    next("/otp/login");
    return;
  } else {
    next();
  }
});

export default router;
