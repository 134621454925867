<template>
  <div class="slider">
    <div class="row">
      <div class="col-lg-9">
        <carousel
          v-if="sliders.length > 0"
          :items="1"
          :nav="false"
          :autoplay="true"
          :autoplayTimeout="4000"
        >
          <a
            v-for="slider in sliders"
            target="_blank"
            :href="slider.url"
            :key="slider.id"
          >

            <img :src="img_base_url + slider.image" />
          </a>
        </carousel>
        <Skeleton height="400px" v-else />
      </div>
      <div class="col-lg-3 advertise_banner">
        <a
          v-if="slider_banner"
          :href="slider_banner.url"
        >
          <img :src="img_base_url + slider_banner.image" class="side_image_banner"/>
        </a>
        <Skeleton height="340px" v-else />
      </div>
    </div>
  </div>
</template>
<script>
import carousel from "vue-owl-carousel";

export default {
  name: "Slider",
  components: {
    carousel,
  },
  created() {
    this.$store.dispatch("sliders");
  },
  computed: {
    
    sliders() {
      return this.$store.state.sliders;
    },
    slider_banner() {
      return this.$store.state.slider_banner;
    },
  },
};
</script>
<style scoped>
.slider {
  margin-top: 20px;
}

@media only screen and (max-width:768px){
  .advertise_banner {
    display: none;
  }
}

</style>