<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="brow bg-white shadow-sm p-3 rounded offer_container">
        <h4 class="text-center text-uppercase f-w-700 f-s-16">
          Browse By Categories
        </h4>
        <div class="offers" v-if="offers">
         <carousel
            :responsive="{ 0: { items: 2 },460: {items: 3}, 600: { items: 4 },800:{items:5}, 1000:{items:6} }"
            :nav="false"
            :autoplay="true"
            :autoplayTimeout="2000"
          >
            <a
              v-for="(offer,index) in offers"
              target="_blank"
              :href="offer.url"
              :key="index"
              class="offer"
            >
              <img  :src="img_base_url + offer.image" />


              <h4 class="offer_caption" style="text-align:center!important">
                {{ offer.name ? offer.name : "" }}
              </h4>
            </a>
          </carousel>
        </div>

        <div class="offers" v-else>
          <p> Please wait ... </p>
          <Skeleton height="200px"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import carousel from "vue-owl-carousel";

export default {
  name: "Offer",
  data(){
    return {
      offers:null
    }
  },
  methods:{
    async fetchOffers(){
      await axios.get("offers")
            .then((resp) => {
               if (resp.data.success == true ) {
                this.offers = resp.data.offers
                
               }
            })
            
           
    }
  },
  components: {
     carousel,
  },
  mounted(){
    this.fetchOffers()
  },
  created(){
     setTimeout(()=>{
        this.fetchOffers()
      },1000)
  }
};
</script>

<style >


@media only screen and (max-width:768px){
  .offer_container {
    height: 225px;
  }

 .owl-theme .owl-dots .owl-dot {
    display: none !important;
  }

}

a.offer {
    padding: 13px;
    text-align: center;
    text-transform: capitalize;
    margin: 3px;
    display: block;
}

h4.offer_caption {
    font-size: 14px;
    color: #000;
    text-align: center;
    margin-top: 3px;
    text-transform: uppercase;
}

a.offer img {
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #ff4d03;
    max-width: none !important;
}
@media screen and (max-width: 450px){
  h4.offer_caption{
    margin-top: 6px;
    font-size: 12px;
  }
}

</style>