<template>
  <div class="container-fluid">
    <Slider />
    <Offer />
    <TopProducts />

    <div v-if="landing_sub_categories">
      <div
        class="c-product"
        v-for="(sub_category, idx) in landing_sub_categories"
        :key="idx"
      >
        <div
          class="c-product-header"
          v-if="sub_category.products && sub_category.products.length"
        >
          <h4 class="category-heading">{{ sub_category.name }}</h4>
          <!-- on mobile view  -->
          <h4
            @click="toggleSubCategory(sub_category.id)"
            class="category-heading-mobile"
          >
            {{ sub_category.name }}
            <i class="bi bi-list-ul angle_icon_on_mobile"></i>
          </h4>
          <!-- on mobile view  -->
          <div
            :id="'s_category_mobile_' + sub_category.id"
            class="s-category"
            v-if="
              sub_category.sub_sub_category &&
                sub_category.sub_sub_category.length > 0
            "
          >
            <div
              :id="'subCategoryNameView' + sub_category.id"
              class="sub_category_view"
            >
              <router-link
                :to="{
                  name: 'sub_sub_category',
                  params: {
                    category: sub_category.category.slug,
                    sub_category: sub_category.slug,
                    slug: sub_sub_category.slug,
                  },
                }"
                class="sub-category-name"
                v-for="(sub_sub_category,
                index) in sub_category.sub_sub_category"
                :key="index"
                >{{ sub_sub_category.name }}</router-link
              >

              <router-link
                :to="{
                  name: 'sub_category',
                  params: {
                    category: sub_category.category.slug,
                    slug: sub_category.slug,
                  },
                }"
                class="c-v-all"
                >View All
              </router-link>
            </div>
          </div>
        </div>

        <Products :products="sub_category.products" />
      </div>
    </div>

    <InfiniteLoading @distance="0.5" @infinite="getCategoryProducts">
      <div slot="no-more"></div>
    </InfiniteLoading>
  </div>
</template>

<script>
// @ is an alias to /src
import Slider from "../components/Slider.vue";
import Offer from "../components/Offer.vue";
import InfiniteLoading from "vue-infinite-loading";
import Products from "../components/Products.vue";
import TopProducts from "../components/TopProduct.vue";
export default {
  name: "Home",
  components: {
    Slider,
    Offer,
    InfiniteLoading,
    Products,
    TopProducts,
  },
  data() {
    return {
      landing_sub_categories: [],
      page: 1,
    };
  },
  methods: {
    getCategoryProducts($state) {
      this.axios
        .get("landing/category/wise/products?page=" + this.page)
        .then((resp) => {
          // console.log(resp);
          if (
            resp.data.success == true &&
            resp.data.sub_categories.data.length > 0
          ) {
            this.page += 1;
            this.landing_sub_categories.push(...resp.data.sub_categories.data);
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch((error) => {
          this.$toastr.e(error.response.data.message);
        });
    },

    toggleSubCategory(id) {
      document
        .getElementById("s_category_mobile_" + id)
        .classList.toggle("sub_c_toggle");
    },
  },
};
</script>
